import { Store } from '@/lib/_types/Store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialTemplateFeatures = [
  '100% Authentic Products',
  'Free & Easy Returns',
  'Nationwide Delivery',
  'Secure Payment',
  '24/7 Customer Support',
  'Exclusive Discounts',
  'New Arrivals',
  'Member Rewards',
  'Gift Wrapping Services',
  'Personalized Recommendations',
  'Fast Shipping',
  'Quality Assurance',
  'Customer Loyalty Program',
  'Multiple Payment Options',
  'Environmental Sustainability',
  'Customizable Products',
  'Live Chat Support',
  'International Shipping',
  'Order Tracking',
  'Special Promotions',
  'Seasonal Sales',
  'Limited Edition Products',
  'Referral Program',
  'Customer Testimonials',
  'Price Match Guarantee',
  'Subscription Services',
  'Virtual Try-On',
  'Augmented Reality Shopping',
  'In-Store Pickup',
  'Same Day Delivery',
  'Gift Cards',
  'Bulk Purchase Discounts',
  'Flash Sales',
  'Pre-Order Options',
  'Product Bundles',
  'Loyalty Points',
  'Mobile App Exclusive Offers',
  'Back-in-Stock Alerts',
  'Wishlist Feature',
  'Product Reviews',
];

type StorefrontState = Pick<
  Store,
  'tagline' | 'domain' | 'logo' | 'features' | 'featuredImages' | 'brandColor'
> & {
  suggestedFeatures: string[];
};

const initialState: StorefrontState = {
  tagline: '',
  domain: '',
  logo: '',
  features: [],
  featuredImages: [],
  brandColor: '',
  suggestedFeatures: initialTemplateFeatures,
};

export const storefrontSlice = createSlice({
  name: 'storefront',
  initialState,
  reducers: {
    updateSuggestedFeatures(
      state,
      action: PayloadAction<{ feature: string; type: 'remove' | 'add' }>
    ) {
      const { feature, type } = action.payload;
      if (type === 'remove') {
        state.suggestedFeatures = state.suggestedFeatures.filter(
          (f) => f !== feature
        );
      }
      if (type === 'add') {
        state.suggestedFeatures.push(feature);
      }
    },
  },
});

export const storefrontActions = storefrontSlice.actions;

export default storefrontSlice.reducer;
