import { Business } from '@/lib/_types/Business';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
type InitialState = {
  loading: ReduxLoading<InitialState>;
  business: Partial<Business> | null;
  businesses: HttpSuccessResponse<Business[]> | null;
};

const initialState: InitialState = {
  loading: {},
  business: null,
  businesses: null,
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setBusiness(state, action: PayloadAction<InitialState['business']>) {
      state.business = action.payload;
    },
    setBusinesses(state, action: PayloadAction<InitialState['businesses']>) {
      state.businesses = action.payload;
    },
  },
});

export const businessActions = businessSlice.actions;

export default businessSlice.reducer;
