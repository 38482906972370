import { Role } from '@/lib/_types/Authentication';
import { ReduxError, ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  errors: ReduxError<InitialState>;
  miniNav: boolean;
  densePadding: boolean;
  lastRoute: string;
  roles: Role[];
  mobileOpen: boolean;
  isClosing: boolean;
};

const initialState: InitialState = {
  errors: {},
  loading: {},
  miniNav: false,
  densePadding: false,
  lastRoute: '',
  roles: [],
  mobileOpen: false,
  isClosing: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    toggleMiniNav(state) {
      state.miniNav = !state.miniNav;
    },
    toggleDensePadding(state) {
      state.densePadding = !state.densePadding;
    },
    setLastRoute(state, action: PayloadAction<InitialState['lastRoute']>) {
      state.lastRoute = action.payload;
    },
    setRoles(state, action: PayloadAction<InitialState['roles']>) {
      state.roles = action.payload;
    },
    setMobileOpen(state, action: PayloadAction<InitialState['mobileOpen']>) {
      state.mobileOpen = action.payload;
    },
    setIsClosing(state, action: PayloadAction<InitialState['isClosing']>) {
      state.isClosing = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
