import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
import { PartialRole, Profile } from '@/lib/_types/Authentication';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  users: HttpSuccessResponse<Profile[]> | null;
  user: Profile | null;
  profile: Profile | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
};

const initialState: InitialState = {
  loading: {},
  users: null,
  user: null,
  profile: null,
  isAdmin: false,
  isSuperAdmin: false,
};

export const checkRole = (
  roles: Profile['roles'] = [],
  check: 'SUPER_ADMIN' | 'ADMIN'
) => {
  return (
    roles.some((role) =>
      ((role as PartialRole)?.operations || [])?.includes(check)
    ) || false
  );
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setUsers(state, action: PayloadAction<InitialState['users']>) {
      state.users = action.payload;
    },
    setUser(state, action: PayloadAction<InitialState['user']>) {
      state.user = action.payload;
    },
    setProfile(state, action: PayloadAction<InitialState['profile']>) {
      state.profile = action.payload;
      state.isSuperAdmin = checkRole(action.payload?.roles, 'SUPER_ADMIN');
      state.isAdmin =
        state.isSuperAdmin || checkRole(action.payload?.roles, 'ADMIN');
    },
    logout: (state) => state, // This doesn't do anything here
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
