import marketingSlice from '@/lib/redux/slices/marketingSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { storage } from '../constants/storage';
import notificationSlice from '@/lib/redux/slices/notificationSlice';
import settingsSlice from '@/lib/redux/slices/settingsSlice';
import paymentSlice from '@/lib/redux/slices/paymentSlice';
import roleSlice from '@/lib/redux/slices/roleSlice';
import subscriptionSlice from '@/lib/redux/slices/subscriptionSlice';
import userSlice, { userActions } from '@/lib/redux/slices/userSlice';
import businessSlice from '@/lib/redux/slices/businessSlice';
import dropDownSlice from '@/lib/redux/slices/dropDownSlice';
import storefrontSlice from '@/lib/redux/slices/storefrontSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const marketingPersistConfig = {
  key: 'marketing',
  storage,
  keyPrefix: 'redux-',
};

const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['notification, notificationSchedule'],
};

const settingsPersistConfig = {
  key: 'settings',
  storage,
  keyPrefix: 'redux-',
};

const paymentPersistConfig = {
  key: 'payment',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['payment'],
};

const subscriptionPersistConfig = {
  key: 'subscription',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['subscription'],
};

const usersPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['user', 'profile', 'isAdmin', 'isSuperAdmin'],
};

const businessPersistConfig = {
  key: 'business',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['business', 'businesses'],
};

const dropDownPersistConfig = {
  key: 'dropdowns',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['states', 'types'],
};

const appReducer = combineReducers({
  marketingSlice: persistReducer(marketingPersistConfig, marketingSlice),
  notificationSlice: persistReducer(
    notificationPersistConfig,
    notificationSlice
  ),
  settingsSlice: persistReducer(settingsPersistConfig, settingsSlice),
  paymentSlice: persistReducer(paymentPersistConfig, paymentSlice),
  roleSlice,
  subscriptionSlice: persistReducer(
    subscriptionPersistConfig,
    subscriptionSlice
  ),
  userSlice: persistReducer(usersPersistConfig, userSlice),
  businessSlice: persistReducer(businessPersistConfig, businessSlice),
  dropDownSlice: persistReducer(dropDownPersistConfig, dropDownSlice),
  storefrontSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action?.type === userActions?.logout?.type) {
    state = undefined;
  }

  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
