import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
import { Payment } from '@/lib/_types/Payment';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  payments: HttpSuccessResponse<Payment[]> | null;
  payment: Payment | null;
};

const initialState: InitialState = {
  loading: {},
  payments: null,
  payment: null,
};

export const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setPayments(state, action: PayloadAction<InitialState['payments']>) {
      state.payments = action.payload;
    },
    setPayment(state, action: PayloadAction<InitialState['payment']>) {
      state.payment = action.payload;
    },
  },
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice.reducer;
