import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
import { ISubscription } from '@/lib/_types/Subscription';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  subscriptions: HttpSuccessResponse<ISubscription[]> | null;
  subscription: ISubscription | null;
};

const initialState: InitialState = {
  loading: {},
  subscriptions: null,
  subscription: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setSubscriptions(
      state,
      action: PayloadAction<InitialState['subscriptions']>
    ) {
      state.subscriptions = action.payload;
    },
    setSubscription(
      state,
      action: PayloadAction<InitialState['subscription']>
    ) {
      state.subscription = action.payload;
    },
  },
});

export const subscriptionActions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
