import { EnvironmentEnum } from '@/lib/_types/Environment';

const isDev = process.env.NEXT_PUBLIC_NODE_ENV === EnvironmentEnum.DEV;

const routes = {
  // TODO: home is ambiguous, likely should be a method, home for home is home, home for store is store home, home for auth is auth home, home for app is app home :)
  home: '/',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-and-conditions',
  siteBuilding: '/features/site-building',
  productManagement: '/features/product-management',
  analytics: '/features/analytics',
  payments: '/features/payments',
  company: '/company',
  pricing: '/pricing',
  api: '/api',
  support: '/support',
  aboutUs: '/company', //'/about-us',
  auth: '/auth',
  signUp() {
    return this.auth + '/signup';
  },
  managePassword() {
    return this.auth + '/manage-password';
  },
  dashboard: '/dashboard',
  onboarding() {
    return this.dashboard + '/onboarding';
  },
  onboardingBusiness() {
    return this.onboarding() + '/?section=business';
  },
  onboardingProducts() {
    return this.onboarding() + '/?section=products';
  },
  onboardingStorefront() {
    return this.onboarding() + '/?section=storefront';
  },
  orders() {
    return this.dashboard + '/orders';
  },
  products() {
    return this.dashboard + '/products';
  },
  customers() {
    return this.dashboard + '/customers';
  },
  teams() {
    return this.dashboard + '/teams';
  },
  wallet() {
    return this.dashboard + '/wallet';
  },
  storefront() {
    return this.dashboard + '/storefront';
  },
  dashboardSupport() {
    return this.dashboard + '/support';
  },
  profile() {
    return this.dashboard + '/profile';
  },
  store: '/store',
  console: '/console',
  users() {
    return this.console + '/users';
  },
  userDetails() {
    return this.users() + '/details';
  },
  subscriptions() {
    return this.console + '/subscriptions';
  },
  subscriptionDetails() {
    return this.subscriptions() + '/details';
  },
  consolePayments() {
    return this.console + '/payments';
  },
  paymentDetails() {
    return this.consolePayments() + '/details';
  },
  notifications() {
    return this.console + '/notifications';
  },
  notificationDetails() {
    return this.notifications() + '/details';
  },
  roles() {
    return this.console + '/roles';
  },
  rolesDetails() {
    return this.roles() + '/details';
  },
};

type Routes = typeof routes;

const substringsToReplace = ['/auth', '/console', '/dashboard'];

const baseUrls: Partial<Record<EnvironmentEnum, Record<string, string>>> = {
  [EnvironmentEnum.TESTING]: {
    '/auth': `https://${EnvironmentEnum.TESTING}.auth.cartinary.com`,
    '/console': `https://${EnvironmentEnum.TESTING}.console.cartinary.com`,
    '/dashboard': `https://${EnvironmentEnum.TESTING}.app.cartinary.com`,
  },
  [EnvironmentEnum.PROD]: {
    '/auth': 'https://auth.cartinary.com',
    '/console': 'https://console.cartinary.com',
    '/dashboard': 'https://app.cartinary.com',
  },
};

const getBaseUrl = (path: string): string => {
  let baseUrl = '';

  if (typeof window === 'undefined' || isDev) {
    return '';
  }

  const hostname = window.location.hostname;

  const environment = hostname.includes(EnvironmentEnum.TESTING)
    ? EnvironmentEnum.TESTING
    : EnvironmentEnum.PROD;

  for (const key in baseUrls[environment]) {
    if (path.startsWith(key)) {
      baseUrl = baseUrls[environment][key];
    }
  }

  if (baseUrl) {
    return baseUrl;
  }

  if (
    environment === EnvironmentEnum.TESTING &&
    hostname !== 'testing.cartinary.com'
  ) {
    baseUrl = 'https://testing.cartinary.com';
  }

  if (environment === EnvironmentEnum.PROD && hostname !== 'cartinary.com') {
    baseUrl = 'https://cartinary.com';
  }

  console.log({
    hostname,
    environment,
    baseUrl,
  });

  return baseUrl;
};

const cleanString = (str: string): string => {
  if (isDev) {
    return str;
  }

  const baseUrl = getBaseUrl(str);

  const regex = new RegExp(substringsToReplace.join('|'), 'g');
  const cleanedStr = str.replace(regex, '');

  return `${baseUrl}${cleanedStr}`;
};

const handler: ProxyHandler<Routes> = {
  get(target, prop: keyof Routes) {
    const value = target[prop];

    if (typeof value === 'string') {
      return cleanString(value);
    }

    if (typeof value === 'function') {
      return (...args: []) => {
        // Explicitly type args as an empty tuple
        const result = value.apply(target, args);
        if (typeof result === 'string' && !isDev) {
          return cleanString(result);
        }
        return result;
      };
    }

    return value;
  },
};

export const ROUTES = new Proxy(routes, handler);
