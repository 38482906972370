import { QueryParamEnum } from '@/lib/hooks/useAppRouter';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: unknown) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

export const storage =
  typeof window === 'undefined'
    ? createNoopStorage()
    : createWebStorage('local');

export const setCookie = (
  name: QueryParamEnum,
  value: string,
  days: number = 7
) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }

  const isLocalhost = window.location.hostname === 'localhost';
  const domain = isLocalhost ? '' : '; domain=.cartinary.com';

  document.cookie = name + '=' + (value || '') + expires + '; path=/' + domain;
};

export const getCookie = (name: QueryParamEnum) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

export const deleteCookie = (name: QueryParamEnum) => {
  setCookie(name, '', -1);
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};
