import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
import { INotification, NotificationSchedule } from '@/lib/_types/Notification';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  notifications: HttpSuccessResponse<INotification[]> | null;
  hasNotification: boolean;
  notificationSchedules: HttpSuccessResponse<NotificationSchedule[]> | null;
  notificationSchedule: NotificationSchedule | null;
};

const initialState: InitialState = {
  loading: {},
  notifications: null,
  hasNotification: false,
  notificationSchedules: null,
  notificationSchedule: null,
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setNotifications(
      state,
      action: PayloadAction<InitialState['notifications']>
    ) {
      state.notifications = action.payload;
    },
    setHasNotification(
      state,
      action: PayloadAction<InitialState['hasNotification']>
    ) {
      state.hasNotification = action.payload;
    },
    setNotificationSchedules(
      state,
      action: PayloadAction<InitialState['notificationSchedules']>
    ) {
      state.notificationSchedules = action.payload;
    },
    setNotificationSchedule(
      state,
      action: PayloadAction<InitialState['notificationSchedule']>
    ) {
      state.notificationSchedule = action.payload;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
