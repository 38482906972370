import { DataLookupDefault, State } from '@/lib/_types/DropDown';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  states: State[];
  types: DataLookupDefault[];
};

const initialState: InitialState = {
  loading: {},
  states: [],
  types: [],
};

export const dropDownSlice = createSlice({
  name: 'dropdowns',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setStates(state, action: PayloadAction<InitialState['states']>) {
      state.states = action.payload;
    },
    setTypes(state, action: PayloadAction<InitialState['types']>) {
      state.types = action.payload;
    },
  },
});

export const dropDownActions = dropDownSlice.actions;

export default dropDownSlice.reducer;
