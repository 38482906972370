import { ApiRoute, Role } from '@/lib/_types/Authentication';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  roles: Role[];
  role: Role | null;
  apiRoutes: ApiRoute[];
};

const initialState: InitialState = {
  loading: {},
  roles: [],
  role: null,
  apiRoutes: [],
};

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setRoles(state, action: PayloadAction<InitialState['roles']>) {
      state.roles = action.payload;
    },
    setRole(state, action: PayloadAction<InitialState['role']>) {
      state.role = action.payload;
    },
    setApiRoutes(state, action: PayloadAction<InitialState['apiRoutes']>) {
      state.apiRoutes = action.payload;
    },
  },
});

export const roleActions = roleSlice.actions;

export default roleSlice.reducer;
