import { TPlan } from '@/lib/_types/Plan';
import { Review } from '@/lib/_types/Review';
import { ReduxLoading } from '@/lib/redux/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  loading: ReduxLoading<InitialState>;
  reviews: Review[];
  plans: TPlan[];
};

const initialState: InitialState = {
  loading: {},
  reviews: [],
  plans: [],
};

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    startLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = true;
    },
    endLoading(state, action: PayloadAction<keyof InitialState>) {
      state.loading[action.payload] = false;
    },
    setReviews(state, action: PayloadAction<InitialState['reviews']>) {
      state.reviews = action.payload;
    },
    setPlans(state, action: PayloadAction<InitialState['plans']>) {
      state.plans = action.payload;
    },
  },
});

export const marketingActions = marketingSlice.actions;

export default marketingSlice.reducer;
